<template>
  <div>
    <div class="air__utils__heading"> <h5>Creación de Entradas</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <reagent-cart transaction-type="entrance" @DeleteItem="DeleteMain"/>
    <hr class="bg-primary">
    <div class="row">
      <div class="col-md-5 col-12">
        <reagent-transaction-form @OnFilterChange="FilterPlant" transaction-type="Entrada"/>
      </div>
      <div class="col-md-7 col-12">
        <reagent-input-table ref="upd" transaction-type="Entrada" table-type="no_details"/>
      </div>
    </div>
  </div>
</template>

<script>
import ReagentCart from '@/views/geocycle/laboratories/reagent_warehouse/components/reagentCart'
import ReagentTransactionForm from '@/views/geocycle/laboratories/reagent_warehouse/components/reagentTransactionForm'
import { fractalMixin } from '@/mixins/fractalMixin'
import ReagentInputTable from '@/views/geocycle/laboratories/reagent_warehouse/components/reagentInputTable'
export default {
  name: 'storageEntrance',
  components: { ReagentInputTable, ReagentTransactionForm, ReagentCart },
  data() {
    return {
      reagents: [],
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'laboratories',
          breadcrumbName: 'Laboratorio',
        },
        {
          name: 'reagentMain',
          breadcrumbName: 'Almacén de Reactivos',
        },
        {
          breadcrumbName: 'Creación de Entradas',
          aIcon: '',
        },
      ],
    }
  },
  mixins: [fractalMixin],
  methods: {
    async getReagents() {
      const params = {}
      this.reagents = await this.GetResource('/reagents', params)
    },
    DeleteMain(data) {
      this.$refs.upd.updateValue(data)
    },
    FilterPlant(data) {
      this.$refs.upd.fetchTablePlant(data)
    },
  },
  mounted() {
    this.getReagents()
  },
}
</script>
