<template>
  <div class="row">
    <div class="col-2 offset-10">
      <responsive-button :block="true"
                         variant="success"
                         responsive="xl"
                         icon="cart3"
                         :text="stateButtonText"
                         @ClickEvent="openCarModal"
                         pill
      />
      <b-modal size="xl"
               title="Reactivos añadidos"
               v-model="showModal"
      >
        <b-container fluid>
          <a-list item-layout="horizontal" :data-source="stateTransactionReagents">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-list-item-meta :description="`Familia: ` + item.reagent_family">
                <div class="row" slot="title">
                  <div class="col-8">
                    <span class="font-weight-bold">Reactivo: </span>
                    {{ item.reagent_name }}
                  </div>
                  <div class="col-4">
                    <span class="font-weight-bold">Cantidad: </span>
                    {{ item.quantity }}
                  </div>
                </div>
                <a-button slot="avatar" @click="deleteItemCart(item)">
                  <a-icon type="delete" theme="twoTone" two-tone-color="#FF0000"/>
                </a-button>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </b-container>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="closeCarModal"/>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'reagentCart',
  props: {
    transactionType: {
      default: 'Entrada',
      type: [String],
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      buttonText: '0 Reactivos añadidos',
    }
  },
  computed: {
    ...mapState('reagents', ['state']),
    stateTransactionReagents () {
      return this.$store.state.reagents.transactionReagents
    },
    stateButtonText() {
      const xState = this.$store.state.reagents.transactionReagents
      const itemsNumber = xState.length
      return `${itemsNumber} Reactivos añadidos`
    },
  },
  methods: {
    ...mapActions('reagents', ['STORE_REAGENT_DATA']),
    openCarModal() {
      this.showModal = true
    },
    closeCarModal() {
      this.showModal = false
    },
    deleteItemCart(data) {
      // Delete data to vuex
      this.$store.dispatch('reagents/DELETE_REAGENT_ITEM', { data })
      this.$emit('DeleteItem', data)
      console.log('Item a borrar: ', data)
    },
    // deleteRecord(data = {}) {
    // },
  },
}
</script>
