<template>
  <div class="row">
    <div class="col-12">
      <h5 class="text-primary text-center">
        Registro de {{ transactionType }}
      </h5>
      <hr class="bg-primary">
    </div>
  </div>
</template>

<script>
export default {
  name: 'reagentTransactionFormTitle',
  props: {
    transactionType: {
      default: 'Entrada',
      type: [String],
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
