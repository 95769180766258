import { fractalMixin } from '@/mixins/fractalMixin'

export const reagentMixin = {
  data() {
    return {
      resourceType: 'manifests',
      users: [],
      reagentProviders: [],
      warehouseTransactions: [],
    }
  },
  mixins: [fractalMixin],
  methods: {},
}
