<template>
  <div>
    <div class="card">
      <div class="card-body">
        <reagent-transaction-form-title :transaction-type="transactionType"/>
        <div class="col-12">
          <a-form-model layout="horizontal" :model="form" :rules="rules" ref="rule-Form">
            <a-row class="mb-0">
              <a-col :lg="{ span: 24 }">
                <a-form-model-item label="Planta:"
                                   class="mx-1"
                                   ref="work_center_id"
                                   prop="work_center_id"
                >
                  <a-select v-model="form.work_center_id"
                            placeholder="Seleccionar"
                            show-search @change="FilterPlant"
                            :filter-option="filterOption"
                  >
                    <a-select-option  v-for="(option) in workCenters" :key="option.id" :value="option.id" >
                      {{option.code}} - {{option.name}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row class="mb-0">
              <a-col :lg="{ span: 24 }">
                <a-form-model-item label="Responsable:"
                                   class="mx-1"
                                   ref="user_id"
                                   prop="user_id"
                >
                  <a-select v-model="form.user_id"
                            placeholder="Seleccionar"
                            show-search
                            :filter-option="filterOption"
                  >
                    <a-select-option  v-for="(option) in users" :key="option.id" :value="option.id" >
                      {{option.name}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <div v-show="transactionType === 'Entrada'">
              <a-row class="mb-0">
                <a-col :lg="{ span: 24 }">
                  <a-form-model-item label="Proveedor de Reactivo:"
                                    class="mx-1"
                                    ref="reagent_provider_id"
                                    prop="reagent_provider_id"
                  >
                    <a-select v-model="form.reagent_provider_id"
                              placeholder="Seleccionar"
                              show-search
                              :filter-option="filterOption"
                    >
                      <a-select-option  v-for="(option) in reagentProviders" :key="option.id" :value="option.id" >
                        {{option.code}} - {{option.name}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
            <div v-show="transactionType === 'Entrada'">
              <a-row class="mb-0">
                <a-col :lg="{ span: 24 }">
                  <a-form-model-item label="Tipo de entrada:"
                                    class="mx-1"
                                    ref="warehouse_transaction_id"
                                    prop="warehouse_transaction_id"
                  >
                    <a-select v-model="form.warehouse_transaction_id"
                              placeholder="Seleccionar"
                              show-search
                              :filter-option="filterOption"
                    >
                      <a-select-option  v-for="(option) in warehouseTransactions" :key="option.id" :value="option.id" >
                        {{option.name}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
            <div v-show="transactionType === 'Salida'">
              <a-row class="mb-0">
                <a-col :lg="{ span: 24 }">
                  <a-form-model-item label="Tipo de salida:"
                                    class="mx-1"
                                    ref="warehouse_transaction_id"
                                    prop="warehouse_transaction_id"
                  >
                    <a-select v-model="form.warehouse_transaction_id"
                              placeholder="Seleccionar"
                              show-search
                              :filter-option="filterOption"
                    >
                      <a-select-option  v-for="(option) in warehouseTransactions" :key="option.id" :value="option.id" >
                        {{option.name}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
            <a-row class="mb-0">
              <a-col :lg="{ span: 24 }">
                <a-form-model-item label="Fecha de Transacción"
                                   class="mx-1"
                                   ref="dated_at"
                                   prop="dated_at"
                >
                  <a-date-picker format="DD/MM/YYYY"
                                 v-model="form.dated_at"
                                 style="width: 100%"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :lg="{ span: 24 }">
                <a-form-model-item label="Motivo de transacción:"
                                   class="mx-1"
                                   ref="reason_transaction"
                                   prop="reason_transaction"
                >
                  <a-textarea
                    v-model="form.reason_transaction"
                    placeholder="Motivo de transacción"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <div class="row" v-show="!load">
              <div class="col-12 text-center">
                <responsive-button responsive="xl"
                                   variant="success"
                                   icon="archive-fill"
                                   text="Guardar"
                                   @ClickEvent="submit"
                                   block
                                   pill
                                   v-show="showStoreButton"
                />
              </div>
            </div>
            <my-spinner :load="load"/>
          </a-form-model>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import api from '@/services/axios/index'
import { mapActions, mapState } from 'vuex'
import { reagentMixin } from '@/mixins/core/reagentMixin'
import ReagentTransactionFormTitle
  from '@/views/geocycle/laboratories/reagent_warehouse/components/reagentTransactionFormTitle'
import { Modal } from 'ant-design-vue'
export default {
  name: 'reagentTransactionForm',
  components: { ReagentTransactionFormTitle },
  props: {
    transactionType: {
      default: 'Entrada',
      type: [String],
      required: true,
    },
  },
  mixins: [reagentMixin],
  data() {
    return {
      form: {
        user_id: '',
        work_center_id: '',
        dated_at: moment.utc(),
        warehouse_transaction_id: '',
        reagent_provider_id: '',
        reason_transaction: '',
      },
      rules: {
        dated_at: [
          { required: true, message: 'El campo de fecha de transacción es obligatorio', trigger: 'blur' },
        ],
        work_center_id: [
          { required: true, message: 'El campo de centro de trabajo es obligatorio', trigger: 'blur' },
        ],
        user_id: [
          { required: true, message: 'El campo de usuario es obligatorio', trigger: 'blur' },
        ],
        warehouse_transaction_id: [
          { required: true, message: 'El campo de tipo de transacción es obligatorio', trigger: 'blur' },
        ],
      },
      load: false,
      // *** Catalogs variables ***
      workCenters: [],
      users: [],
      warehouseTransactions: [],
      reagentProviders: [],
      // *** Catalogs variables ***
    }
  },
  computed: {
    ...mapState('reagents', ['state']),
    stateTransactionReagents () {
      return this.$store.state.reagents.transactionReagents
    },
    showStoreButton () {
      const numberItems = this.$store.state.reagents.transactionReagents.length
      return numberItems > 0
    },
  },
  methods: {
    ...mapActions('reagents', ['STORE_REAGENT_DATA', 'CLEAN_STATE']),
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    FilterPlant(data) {
      // Delete data to vuex
      this.$emit('OnFilterChange', data)
    },
    async getViewCatalogs() {
      const params = {}
      this.workCenters = await this.GetResource('workCenters', params)
      this.users = await this.GetResource('users', params)
      const warehouseTransactionsParams = {
        'filter[transaction_type]': this.transactionType,
      }
      this.warehouseTransactions = await this.GetResource('warehouseTransactions', warehouseTransactionsParams)
      this.reagentProviders = await this.GetResource('reagentProviders', params)
      if (this.transactionType === 'Entrada') {
        this.rules.reagent_provider_id = [{ required: true, message: 'El campo de proveedor de reactivo es obligatorio', trigger: 'blur' }]
      }
    },
    submit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          this.load = true
          this.form.dated_at = moment(this.form.dated_at).format('YYYY-MM-DD')
          const request = {
            transaction_type: this.transactionType,
            form: this.form,
            reagent_transactions_cart: this.stateTransactionReagents,
          }
          api.post('/storeReagentTransaction', request).then(response => {
            if (response.status === 200 || response.status) {
              const data = response.data.data
              this.load = false
              Modal.success({
                title: '¡Transacción guardada!',
                content: 'Se ha almacenado correctamente la información de la transacción con folio: ' + data.reagentTransaction.code,
              })
              this.$store.dispatch('reagents/CLEAN_STATE')
              this.$router.push({ name: 'reagentMain', params: {} })
            }
          }).catch(() => {
            this.load = false
          })
        } else {
          return false
        }
      })
    },
  },
  mounted() {
    this.getViewCatalogs()
  },
}
</script>
